import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EdDSATicketPCDPackage, TicketCategory, getEdDSATicketData } from "@pcd/eddsa-ticket-pcd";
import { ZUCONNECT_23_DAY_PASS_PRODUCT_ID } from "@pcd/passport-interface";
import { styled } from "@pcd/passport-ui";
import { useRef } from "react";
import { TicketQR } from "./TicketQR.js";
export const EdDSATicketPCDUI = {
    renderCardBody: EdDSATicketPCDCardBody,
    getHeader
};
function EdDSATicketPCDCardBody({ pcd, verifyURL, idBasedVerifyURL, addOns }) {
    const ticketImageRef = useRef(null);
    const ticketData = getEdDSATicketData(pcd);
    // If ticket has an `eventStartDate` render the `qrCodeOverrideImageUrl`, if it exists
    // Else, render the `imageUrl`, if it existss
    const imageToRender = ticketData?.eventStartDate
        ? ticketData.qrCodeOverrideImageUrl
        : ticketData?.imageUrl;
    return (_jsxs(NEW_UI__Container, { children: [_jsxs(NEW_UI__TicketImageContainer, { ref: ticketImageRef, children: [!imageToRender && (_jsx(TicketQR, { pcd: pcd, verifyURL: verifyURL, idBasedVerifyURL: idBasedVerifyURL })), imageToRender && (_jsx(TicketImage, { imageUrl: imageToRender, imageAltText: ticketData?.imageAltText, hidePadding: true })), _jsxs(NEW_UI__InfoContainer, { children: [_jsx(NEW_UI__AttendeeName, { children: ticketData?.attendeeName.toUpperCase() ||
                                    ticketData?.eventName.toUpperCase() ||
                                    "Unknown" }), _jsxs(NEW_UI__ExtraInfoContainer, { children: [ticketData?.attendeeEmail && (_jsx(NEW_UI__ExtraInfo, { children: ticketData.attendeeEmail })), ticketData?.attendeeEmail && ticketData?.ticketName && (_jsx(NEW_UI__ExtraInfo, { children: "\u2022" })), ticketData?.ticketName && (_jsx(NEW_UI__ExtraInfo, { children: ticketData.ticketName }))] })] })] }), _jsxs("div", { children: [addOns && (_jsxs(NEW_UI__ExtraSection, { onClick: addOns.onClick, children: [_jsx(NEW_UI__ExtraSectionText, { children: addOns.text }), _jsx(QRIcon, {})] })), _jsx(NEW_UI__ExtraSection, { style: { justifyContent: "center", cursor: "default" }, children: _jsx(ExtraSectionSecondaryText, { children: "QR POD \u2022 ZK powered by ZUPASS" }) })] })] }));
}
function TicketImage({ imageUrl, imageAltText, hidePadding }) {
    if (hidePadding)
        return _jsx("img", { src: imageUrl, alt: imageAltText });
    return (_jsx("div", { style: { padding: "8px" }, children: _jsx("img", { src: imageUrl, alt: imageAltText }) }));
}
function getHeader({ pcd }) {
    let header;
    if (pcd.claim.ticket.ticketCategory === TicketCategory.ZuConnect &&
        pcd.claim.ticket.productId === ZUCONNECT_23_DAY_PASS_PRODUCT_ID) {
        header = "ZUCONNECT '23 DAY PASS";
    }
    else {
        header = EdDSATicketPCDPackage.getDisplayOptions?.(pcd).header ?? "";
    }
    return _jsx(Uppercase, { children: header });
}
const Uppercase = styled.span `
  text-transform: uppercase;
`;
const NEW_UI__Container = styled.div `
  font-family: Rubik;
  border-radius: 16px;
  border: 2px solid var(--text-white, #fff);
  background: #fff;

  /* shadow-sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const NEW_UI__TicketImageContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 0px 16px;
  background: var(--bg-white-transparent, rgba(255, 255, 255, 0.8));
  border-radius: inherit;
`;
const NEW_UI__InfoContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
const NEW_UI__AttendeeName = styled.div `
  color: #9a4ac9;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 135%; /* 27px */
  font-family: Barlow, sans-serif;
`;
const NEW_UI__ExtraInfoContainer = styled.div `
  display: flex;
  gap: 4px;
`;
const NEW_UI__ExtraInfo = styled.div `
  color: var(--text-primary);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
`;
const NEW_UI__ExtraSection = styled.div `
  display: flex;
  flex-direction: row;
  border-top: 1px solid #eee;
  padding: 16px;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  &:focus {
    outline: none;
    background-color: "var(--text-tertiary)";
  }
  &:active {
    background-color: "var(--text-tertiary)";
  }
`;
const NEW_UI__ExtraSectionText = styled.div `
  color: ${({ $disabled }) => $disabled ? "var(--text-tertiary)" : "var(--text-primary)"};
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 135%;
`;
const ExtraSectionSecondaryText = styled.div `
  color: var(--text-tertiary);
  text-align: center;

  /* text-xs (12px)/regular-rubik */
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 16.2px */
`;
const QRIcon = () => (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "var(--text-tertiary)", className: "size-4", width: 20, height: 20, children: [_jsx("path", { d: "M4.75 4.25a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Z" }), _jsx("path", { fillRule: "evenodd", d: "M2 3.5A1.5 1.5 0 0 1 3.5 2H6a1.5 1.5 0 0 1 1.5 1.5V6A1.5 1.5 0 0 1 6 7.5H3.5A1.5 1.5 0 0 1 2 6V3.5Zm1.5 0H6V6H3.5V3.5Z", clipRule: "evenodd" }), _jsx("path", { d: "M4.25 11.25a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z" }), _jsx("path", { fillRule: "evenodd", d: "M2 10a1.5 1.5 0 0 1 1.5-1.5H6A1.5 1.5 0 0 1 7.5 10v2.5A1.5 1.5 0 0 1 6 14H3.5A1.5 1.5 0 0 1 2 12.5V10Zm1.5 2.5V10H6v2.5H3.5Z", clipRule: "evenodd" }), _jsx("path", { d: "M11.25 4.25a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Z" }), _jsx("path", { fillRule: "evenodd", d: "M10 2a1.5 1.5 0 0 0-1.5 1.5V6A1.5 1.5 0 0 0 10 7.5h2.5A1.5 1.5 0 0 0 14 6V3.5A1.5 1.5 0 0 0 12.5 2H10Zm2.5 1.5H10V6h2.5V3.5Z", clipRule: "evenodd" }), _jsx("path", { d: "M8.5 9.417a.917.917 0 1 1 1.833 0 .917.917 0 0 1-1.833 0ZM8.5 13.083a.917.917 0 1 1 1.833 0 .917.917 0 0 1-1.833 0ZM13.083 8.5a.917.917 0 1 0 0 1.833.917.917 0 0 0 0-1.833ZM12.166 13.084a.917.917 0 1 1 1.833 0 .917.917 0 0 1-1.833 0ZM11.25 10.333a.917.917 0 1 0 0 1.833.917.917 0 0 0 0-1.833Z" })] }));
